import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { css } from 'react-emotion';
import Helmet from 'react-helmet';

import Footer from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import PostCard from '../components/PostCard';
import Wrapper from '../components/Wrapper';
import IndexLayout from '../layouts';
import config from '../website-config';
import {
  inner,
  outer,
  PostFeed,
  PostFeedRaise,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from '../styles/shared';

const NavLink = props => {
    if (!props.isFirstLastOrCurrent) {
        return <Link to={props.url}>{props.text}</Link>;
    }

    return <span>{props.text}</span>;
};

// I like 6n + 3 and 5n + 4 here
const HomePosts = css`
  @media (min-width: 795px) {
    .post-card:nth-child(6n + 5):not(.no-image) {
      flex: 1 1 100%;
      flex-direction: row;
    }

    .post-card:nth-child(6n + 5):not(.no-image) .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      border-radius: 5px 0 0 5px;
    }

    .post-card:nth-child(6n + 5):not(.no-image) .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card:nth-child(6n + 5):not(.no-image) .post-card-content {
      flex: 0 1 357px;
    }

    .post-card:nth-child(6n + 5):not(.no-image) h2 {
      font-size: 2.6rem;
    }

    .post-card:nth-child(6n + 5):not(.no-image) p {
      font-size: 1.8rem;
      line-height: 1.55em;
    }

    .post-card:nth-child(6n + 5):not(.no-image) .post-card-content-link {
      padding: 30px 40px 0;
    }

    .post-card:nth-child(6n + 5):not(.no-image) .post-card-meta {
      padding: 0 40px 30px;
    }
  }
  
  @media (min-width: 795px) {
    .post-card:nth-child(7n + 2):not(.no-image) {
      flex: 1 1 100%;
      flex-direction: row;
    }

    .post-card:nth-child(7n + 2):not(.no-image) .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      border-radius: 5px 0 0 5px;
    }

    .post-card:nth-child(7n + 2):not(.no-image) .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card:nth-child(7n + 2):not(.no-image) .post-card-content {
      flex: 0 1 357px;
    }

    .post-card:nth-child(7n + 2):not(.no-image) h2 {
      font-size: 2.6rem;
    }

    .post-card:nth-child(7n + 2):not(.no-image) p {
      font-size: 1.8rem;
      line-height: 1.55em;
    }

    .post-card:nth-child(7n + 2):not(.no-image) .post-card-content-link {
      padding: 30px 40px 0;
    }

    .post-card:nth-child(7n + 2):not(.no-image) .post-card-meta {
      padding: 0 40px 30px;
    }
  }
`;

const PaginationWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 25px;
  
  > * {
    margin: 0px 5px;
    
    &:first-child, &:last-child {
        margin: 0px 10px;
    }
  }
`;

export interface IndexProps {
    data: {
        logo?: {
            childImageSharp: {
                fixed: any;
            };
        };
        header: {
            childImageSharp: {
                fluid: any;
            };
        };
    };
}


const IndexPage: React.SFC<IndexProps> = props => {
    const { group, index, first, last, pageCount } = props.pathContext;
    const previousUrl = index - 1 === 1 ? "" : (index - 1).toString();
    const nextUrl = (index + 1).toString();

    const paginationElements = [];
    if (pageCount > 1) {
        paginationElements.push(<div key={'previousLink'} className="previousLink">
            <NavLink isFirstLastOrCurrent={first} url={previousUrl} text="Go to Previous Page" />
        </div>);

        for (const x of Array(pageCount).keys()) {
            paginationElements.push(
                <NavLink isFirstLastOrCurrent={index - 1 === x} url={x === 0 ? "" : (x+1).toString()} text={x + 1} />
            );
        }
        paginationElements.push(<div key={'nextLink'} className="nextLink">
            <NavLink isFirstLastOrCurrent={last} url={nextUrl} text="Go to Next Page" />
        </div>);
    }

    const width = props.data.header.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
    const height = String(Number(width) / props.data.header.childImageSharp.fluid.aspectRatio);
    return (
        <IndexLayout className={`${HomePosts}`}>
          <Helmet>
            <title>{config.seoTitle}</title>
            <meta name="description" content={config.seoDescription} />

            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="#D0E0D8" />
            <meta name="apple-mobile-web-app-title" content="Lazywill" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-57x57.png" sizes="57x57" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-60x60.png" sizes="60x60" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-72x72.png" sizes="72x72" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-76x76.png" sizes="76x76" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-114x114.png" sizes="114x114" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-120x120.png" sizes="120x120" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-144x144.png" sizes="144x144" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-152x152.png" sizes="152x152" />
            <link rel="apple-touch-icon" href="/icons/apple-touch-icon-180x180.png" sizes="180x180" />
            <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />

            <meta property='article:author' content={config.facebookUrl} />
            <meta property="article:publisher" content={config.facebookUrl} />

            <meta property="og:site_name" content={config.title} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={config.title} />
            <meta property="og:description" content={config.description} />
            <meta property="og:url" content={config.siteUrl} />
            <meta property="og:image" content={props.data.header.childImageSharp.fluid.src} />
            <meta property="og:image:width" content={width} />
            <meta property="og:image:height" content={height} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={config.title} />
            <meta name="twitter:description" content={config.description} />
            <meta name="twitter:url" content={config.siteUrl} />
            <meta name="twitter:image" content={props.data.header.childImageSharp.fluid.src} />
            <meta name="twitter:site" content={config.twitterHandle} />

            {/*<meta property="fb:app_id" content="1234" />*/}
          </Helmet>
          <Wrapper>
            <header
                className={`${SiteHeader} ${outer}`}
                style={{
                    // backgroundImage: `url('${props.data.header.childImageSharp.fluid.src}')`,
                }}
            >
              <div className={`${inner}`}>
                <SiteHeaderContent>
                  <SiteTitle>
                      {props.data.logo ? (
                          <div style={{ display: 'flex', height: '150px' }}>
                            <img
                                style={{ maxHeight: '150px', borderRadius: '200px', marginRight: '25px' }}
                                src={props.data.logo.childImageSharp.fixed.src}
                                alt={config.title}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                {config.title}
                              <SiteDescription>{config.description}</SiteDescription>
                            </div>

                          </div>
                      ) : (
                          config.title
                      )}
                  </SiteTitle>

                </SiteHeaderContent>
                <SiteNav isHome={true} />
              </div>
            </header>
            <main id="site-main" className={`${SiteMain} ${outer}`}>
              <div className={`${inner}`}>
                <div className={`${PostFeed} ${PostFeedRaise}`}>
                    {group.map(post => {
                        return <PostCard key={post.node.fields.slug} post={post.node} />;
                    })}
                </div>
              </div>
            </main>
            {props.children}
            <div className={`${PaginationWrapper}`}>
                {paginationElements}
            </div>
            <Footer />
          </Wrapper>
        </IndexLayout>
    );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "avatars/avatar_400_400.jpg" }) {
      childImageSharp {
      # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed {
          ...GatsbyImageSharpFixed
          }
      }
    }
    header: file(relativePath: { eq: "img/ivan-diaz-452850-unsplash.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;